import React, {useState} from 'react';
import styled from 'styled-components';

import { 
  Button as MuiButton, 
  Menu, 
  MenuItem 
} from '@material-ui/core';

import {
  Loop as LoopIcon,
} from '@material-ui/icons';

import { spacing } from '@material-ui/system';

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

const Actions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDate] = useState(new Date());

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      
      {/* <SmallButton size="small" mr={2}>
          <FilterListIcon />
        </SmallButton> */}
      <Button
        variant="contained"
        size="small"
        color="secondary"
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
          Today: {currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getDate()}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Today</MenuItem>
        <MenuItem onClick={handleClose}>Yesterday</MenuItem>
        <MenuItem onClick={handleClose}>Last 7 days</MenuItem>
        <MenuItem onClick={handleClose}>Last 30 days</MenuItem>
        <MenuItem onClick={handleClose}>This month</MenuItem>
        <MenuItem onClick={handleClose}>Last month</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default Actions;
