import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({

    paper: {
        paddingTop: `env(safe-area-inset-top)`,
        paddingBottom: `env(safe-area-inset-bottom)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
      }
}));